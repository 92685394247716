import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from "react-icons/ai";
import Particle from "../../Particle";
import pdf from "./DerekMiller_GameDev.pdf";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Zoom from "react-reveal/Zoom";
import ResumeLink from "./DerekMiller_GameDev.pdf"
import React, { useState, useEffect } from "react";
import {sendPanelName, sendResumeDownload} from "../../utils"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const resumeLink =
//   "./MD_MAFUJUL_HASAN.pdf";

function Resume({locationData}) {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
    sendPanelName(locationData, "Resume")
  }, []);

  return (
    <div>
      <section className="home-section">
        <Container fluid id="home">
          <Particle />
          <Container className="home-content">
            <div
              className="d-flex justify-content-center"
              width="100%"
              style={{ backgroundColor: "#fbd9ad" }}
            >
              <Zoom left cascade>
                <h1 style={{ color: "rgb(134 61 176)" }}>RESUME</h1>
              </Zoom>
            </div>
            <div fluid className="certificate-section" id="about">
              <div className="d-flex justify-content-center mt-4">
                <Button variant="primary" href={pdf} target="_blank" onClick={sendResumeDownload}>
                  <AiOutlineDownload />
                  &nbsp;Download Resume
                </Button>
              </div>
              <div className="resume d-flex justify-content-center">
              {
                locationData.isRestricted || locationData.isvpn ? (
                    null
                  ) :(
                    <Document file={ResumeLink}>
                      <Page pageNumber={1} scale={width > 786 ? 1.6 : 0.4} />
                      {/* <Page pageNumber={2} scale={width > 786 ? 1.6 : 0.4} /> */}
                    </Document>)
              }
              </div>
              <div className="d-flex justify-content-center">
                <Button variant="primary" href={pdf} target="_blank" onClick={sendResumeDownload}>
                  <AiOutlineDownload />
                  &nbsp;Download Resume
                </Button>
              </div>
            </div>
          </Container>
        </Container>
      </section>
    </div>
  );
}

export default Resume;
