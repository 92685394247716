import React, {useEffect, useState} from "react";
import Particle from "../../Particle";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import { FaCode } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import data from "../../components/data"
import {sendPanelName, sendProjectName} from "../../utils"
export default function ProjectPage({locationData}) {

  const [duration,setDuration] = useState(0)

  const handleMouseOver = (project_name)=>{
      setDuration(Date.now())
  }
  const handleMouseLeave = (project_name)=>{
      let during_time = 0
      sendProjectName(locationData, project_name, during_time)
  }

  useEffect(()=>{
    // console.log(locationData)
    sendPanelName(locationData, "ProjectPage")
  },[locationData])

  return (
    <section className="home-section">
      <Container fluid id="home">
        <Particle />
        <Container className="home-content">
          <div
            className="d-flex justify-content-center"
            width="100%"
            style={{ backgroundColor: "#fbd9ad" }}
          >
            <Zoom left cascade>
              <h1 style={{ color: "rgb(134 61 176" }}>PROJECTS</h1>
            </Zoom>
          </div>
          <div>
            <div>
              <Container fluid className="certificate-section" id="about">
                <Container>
                  <Row>
                    <Col md={12} className="mt-5">
                      <Row className="g-5">
                      {
                            locationData.isRestricted || locationData.isvpn ? null : data.map((node,i)=>(<Col md={3}>
                            <Fade bottom>
                              <div
                                key={i+"_project"}
                                className="singleProject"
                                style={{
                                  backgroundColor: "rgb(142 70 186 / 31%)",
                                  border: "1px solid",
                                  padding:"10px 0px 0px 0px"
                                }}
                                // onMouseEnter={()=>handleMouseOver(node.title)}
                                onClick={()=>handleMouseLeave(node.title)}
                              >
                                <div className="projectContent">
                                  <h5 id={node.title} style={{ color: "#fbd9ad" }}>
                                    {node.title}
                                  </h5>
                                  <img src={node.img} alt={node.img} />
                                  <div className="project--showcaseBtn">
                                    <a
                                      href={
                                        node.link
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      className={"iconBtn"}
                                      aria-labelledby={`code`}
                                    >
                                      <FaSearch
                                        className={"icon"}
                                        aria-label="Code"
                                      />
                                    </a>
                                  </div>
                                </div>
                                <h6>
                                  <p
                                    className="project--desc"
                                    style={{
                                      background: "#fbd9ad",
                                      color: "#b061df",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {node.description}
                                  </p>
                                </h6>
                                {/* <div
                                  className="project--lang"
                                  style={{
                                    background: "#fbd9ad",
                                    color: "#b061df",
                                    fontWeight: 600,
                                  }}
                                >
                                  {node.skills}
                                </div> */}
                              </div>
                            </Fade>
                            </Col>))
                          }
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>
          </div>
        </Container>
      </Container>
    </section>
  );
}
