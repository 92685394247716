import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import ucsc from "../../images/ucsc.png";
import freecodecamp from "../../images/freecodecamp.png";
import Particle from "../../Particle";
import { certificates } from "../../components/data";
import {sendPanelName, sendCertName} from "../../utils"
export default function CertificatePage({locationData}) {

  useEffect(()=>{
    sendPanelName(locationData, "CertificatePage")
  },[])

  return (
    <section className="home-section">
      <Container fluid id="home">
        <Particle />
        <Container className="home-content">
          <div
            className="d-flex justify-content-center"
            width="100%"
            style={{ backgroundColor: "#fbd9ad" }}
          >
            <Zoom left cascade>
              <h1 style={{ color: "rgb(134 61 176" }}>Certificates</h1>
            </Zoom>
          </div>
          <div>
            <div>
              <Container fluid className="certificate-section" id="about">
                <Container>
                  <Row>
                    <Col md={12} className="mt-5">
                      <Row className="g-5">
                      {
                        locationData.isRestricted || locationData.isvpn ? null : certificates.map((cert,i)=>
                        <Col md={4} className="col-sm-12 col-md4" key = {i + "_cert"}>
                          <Fade bottom duration={2000} distance="20px">
                            <div className="cert-card" onClick={()=>sendCertName(locationData, cert.title)}>
                              <div className="content">
                                <a
                                  href={cert.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="content-overlay"></div>
                                  <div
                                    className="cert-header"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {<img
                                      className="logo_img"
                                      src={"/photos/"+cert.platform.toLowerCase()+".png"}
                                      alt={cert.platform.toLowerCase()}
                                    />}
                                  </div>
                                  <div className="content-details fadeIn-top">
                                    <h3
                                      className="content-title"
                                      style={{ color: "black" }}
                                    >
                                      Certificate
                                    </h3>
                                  </div>
                                </a>
                              </div>
                              <div className="cert-body">
                                <h2
                                  className="cert-body-title"
                                  style={{ fontWeight: 700, color: "#fbd9ad" }}
                                >
                                  {cert.title}
                                </h2>
                                <h3
                                  className="cert-body-subtitle"
                                  style={{
                                    color: "#eb90ff",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - {cert.platform}
                                </h3>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                        )
                      }
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>
          </div>
          <div className="blog--viewAll"></div>
        </Container>
      </Container>
    </section>
  );
}
