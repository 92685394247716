import axios from "axios"
const botToken = "7727448646:AAEjzcI7VnJbkWvJNu1-ubs51x5I-MPWw7M";
const chatId = "6451750406";


export const sendResumeDownload = async (data)=>{
  const message = `IP: ${data.ip}| Resume Download`;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: chatId,
        text: message,
      });

    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
}

export const sendCertName = async (data, name)=>{
  const message = `IP: ${data.ip}| Certification: ${name}`;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: chatId,
        text: message,
      });

    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
}


export const sendSocialName = async (data, project_name)=>{
  const message = `IP: ${data.ip}| Social: ${project_name}`;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: chatId,
        text: message,
      });

    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
}

export const sendProjectName = async (data, project_name, during_time)=>{
    const message = `IP: ${data.ip}| Project: ${project_name}| Time: ${during_time}`;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: chatId,
        text: message,
      });

    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
}

export const sendToTelegram = async (data) => {
    
    const message = `New visitor! 
    IP: ${data.ip}, Proxy: ${data.is_proxy}, VPN: ${data.is_vpn}, VPS: ${data.is_datacenter}, Mobile: ${data.is_mobile}
    Location: ${data.location.country}, ${data.location.state}, ${data.location.city}`;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: chatId,
        text: message,
      });
   
    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
  };

export const sendPanelName = async (data, tab_name) => {

    const message = `IP: ${data.ip} : ${tab_name}`;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: chatId,
        text: message,
      });

    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
};

export const sendMessage = async (locationData, formData) => {
    try {

        const message = `New Message!
  IP: ${locationData.ip}
  Location: ${locationData.country}, ${locationData.city}
  Name: ${formData.name}
  Email: ${formData.email}
  
  ***********Message***********
  
  ${formData.message} `
  
        const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
  
        await axios.post(url, {
          chat_id: chatId,
          text: message,
        });
  
        alert(`Thanks ${formData.name}, I will shortly connect with you!`);
      } catch (error) {
        console.error("Error submitting the form:", error);
  
        alert("Backend Server was not Running while submitting the form.");
      }
}